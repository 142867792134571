/* eslint-disable max-lines */
import { useEffect, useContext, useCallback, useMemo, useState } from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import {
  Article,
  ArticleAlternativeContentNumber,
  ArticleResolution,
  ArticleType,
  Category,
  Label,
} from '@wix/answers-api';
import { Box, FloatingNotification } from '@wix/design-system';
import { useExperiments, useTranslation } from '@wix/fe-essentials-standalone';
import { isProduction } from '@wix/wix-run-mode';
import cheerio from 'cheerio';
import classNames from 'classnames';
import {
  EXPERIMENTS,
  LOCALES,
  MOCKED_ROADMAP_LABELS,
} from '../../constants';
import { AB_TESTING_LABELS, GetArticleResponse, PageType, PAGES } from '../../types';
import {
  getArticle,
  getArticleById,
  getLabels,
  getPageSeo,
} from '../../services/answers';
import { mapArticleTypeToItemType } from '../../utils/bi';
import { ArticleContent } from '../../components/ArticleContent';
import {
  withAppServerProps,
} from '../../utils/appServerProps';
import { useHistoryContext } from '../../hooks/useRouteHistory';
import { getRoadmapLabels, isRoadmapArticle } from '../../utils/roadmap';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Context } from '../../context';
import { getCategoriesBreadcrumb } from '../../utils/categories';
import css from './Article.module.scss';
import { getWixDocsUrl } from '../../services/wixDocsRedirect';
import { shouldRedirectArticle } from '../../utils/redirectToWixDocs';
import { ArticleSideBar } from '../../components/ArticleSidebar';
import { WIX_EDITOR_X_CATEGORY_ID } from '../../components/CategoryPageWrapper';
import { RedirectionError } from '../../utils/extractHttpErrorCode';
import StatusWarning from 'wix-ui-icons-common/StatusWarning';
import { DATA_HOOKS } from '../../dataHooks';
import { useArticlePageBi } from '../../utils/articlePageBI';
import { LoggerNamespaces, createLogger } from '../../utils/logger';
import { isSledUserAgent } from '../../utils/userAgent';

export type Step = {
  id: string;
  text: string | null;
  level: string | null;
};

export type ArticlePageProps = {
  relatedArticles: Article[];
  article: Article;
  roadmapLabels: Label[];
  steps: Step[];
};

const ArticlePage: NextPage<ArticlePageProps> = ({
  article,
  relatedArticles,
  roadmapLabels,
  steps,
}: ArticlePageProps) => {
  const { locale } = useRouter();
  const { prePageUrl } = useHistoryContext();
  const { categoriesTree, isWixStudioUser } = useContext(Context);
  const { t } = useTranslation();
  const router = useRouter();
  const { isWixEmployee } = useContext(Context);
  const { experiments } = useExperiments({ readOnly: true });
  const [isShowAbTestNotificationClosed, setIsShowAbTestNotificationClosed] = useState(false);
  const isArticlePageUXChangesEnabled = experiments.enabled(EXPERIMENTS.SPECS.ARTICLE_PAGE_UX_CHANGES);

  const {
    breadCrumbLinkClick,
    homePageClick,
    articleIsBeingAbTestedView,
    articleIsBeingAbTestedClick,
  } = useArticlePageBi(article);

  const isFeatureRequestArticle = article.type === ArticleType.FEATURE_REQUEST;
  const showSideBar =
    isArticlePageUXChangesEnabled ? article.type === ArticleType.ARTICLE : (
      steps.length > 0 ||
      relatedArticles.length > 0 ||
      (isWixStudioUser && isFeatureRequestArticle));

  const alternativeContent = router.query.alternativeContent === 'true';
  const shouldShowABTestNotification = isWixEmployee && !isShowAbTestNotificationClosed &&
    article.abTestInfo?.experimentId;

  useEffect(() => {
    void (async () => {
      if (shouldShowABTestNotification) {
        const labelText = t('article.ab-test-notification', {
          version: alternativeContent
            ? AB_TESTING_LABELS.B
            : AB_TESTING_LABELS.A,
        });
        const ctaText = t('article.ab-test-notification-open-version', {
          version: alternativeContent
            ? AB_TESTING_LABELS.A
            : AB_TESTING_LABELS.B,
        });
        const text = `${labelText} ${ctaText}`;
        await articleIsBeingAbTestedView(text);
      }
    })();
  }, [
    alternativeContent,
    articleIsBeingAbTestedView,
    shouldShowABTestNotification,
  ]);

  const onBreadCrumbLinkClick = useCallback(
    async (category: Category, clickedItemOrder?: number): Promise<void> =>
      breadCrumbLinkClick(category, clickedItemOrder),
    [breadCrumbLinkClick]
  );

  const isRoadmap =
    article.resolution &&
    article.labels &&
    isRoadmapArticle(article.resolution, article.labels, roadmapLabels);

  const roadmapBreadcrumbs = {
    label: t('roadmap.breadcrumbs'),
    uri: '/roadmap',
  };
  const isPrePageIsRoadmap = prePageUrl?.includes('/roadmap');

  const articleBreadcrumbItem = { id: article.id, label: article.title };
  const categoriesBreadcrumb = getCategoriesBreadcrumb(
    categoriesTree,
    article.categoryId,
    onBreadCrumbLinkClick
  );
  const breadcrumbItems = useMemo(
    () =>
      locale === LOCALES.EN && isRoadmap && isPrePageIsRoadmap
        ? [roadmapBreadcrumbs, articleBreadcrumbItem]
        : [...categoriesBreadcrumb, articleBreadcrumbItem],
    [categoriesTree, article]
  );

  const isEditorXSubCategory =
    categoriesBreadcrumb
      .map((item) => item.id)
      .includes(WIX_EDITOR_X_CATEGORY_ID) &&
    experiments.enabled(EXPERIMENTS.SPECS.EDITOR_X_NOTIFICATION);

  const onHomePageClick = useCallback(
    async (label: string) => {
      if (window) {
        await homePageClick(label);
      }
    },
    [homePageClick]
  );

  const onSelectStatus = async (resolution: ArticleResolution) => {
    await router.push(`/roadmap?resolution=${resolution}`);
  };

  const abTestNotificationClick = async () => {
    const labelText = t('article.ab-test-notification', {
      version: alternativeContent ? AB_TESTING_LABELS.B : AB_TESTING_LABELS.A,
    });
    const ctaText = t('article.ab-test-notification-open-version', {
      version: alternativeContent ? AB_TESTING_LABELS.A : AB_TESTING_LABELS.B,
    });
    const text = `${labelText} ${ctaText}`;

    await articleIsBeingAbTestedClick(
      text,
      ctaText,
      `${locale}${article.uri}?alternativeContent=${!alternativeContent}`
    );
  };

  return (
    <>
      <Breadcrumbs
        onHomePageClick={onHomePageClick}
        breadcrumbItems={breadcrumbItems}
        shallow={false}
      />
      {shouldShowABTestNotification ? (
        <FloatingNotification
          prefixIcon={<StatusWarning />}
          dataHook={DATA_HOOKS.ARTICLE_AB_TEST_NOTIFICATION}
          type="warning"
          onClose={() => setIsShowAbTestNotificationClosed(true)}
          text={t('article.ab-test-notification', {
            version: alternativeContent
              ? AB_TESTING_LABELS.B
              : AB_TESTING_LABELS.A,
          })}
          textButtonProps={{
            label: t('article.ab-test-notification-open-version', {
              version: alternativeContent
                ? AB_TESTING_LABELS.A
                : AB_TESTING_LABELS.B,
            }),
            as: 'a',
            onClick: abTestNotificationClick,
            href: `${locale}${article.uri}?alternativeContent=${!alternativeContent}`,
            target: '_blank',
          }}
          fullWidth
        />
      ) : null}
      <Box
        direction="horizontal"
        width="100%"
        align='center'
        className={css.articleWrapper}>
        <ArticleContent
          article={article}
          isRoadmapArticle={isRoadmap && locale === LOCALES.EN}
          readingTimeInMinutes={article.readingTimeInMinutes}
          onClickStatus={onSelectStatus}
          showEditorXNotification={isEditorXSubCategory}
          relatedArticles={relatedArticles}
          steps={steps}
        />
        {showSideBar && (
          <Box
            direction="vertical"
            position="relative"
            className={classNames({
              [css.rightSide]: !isArticlePageUXChangesEnabled,
              [css.rightSideNew]: isArticlePageUXChangesEnabled,
            })}
          >
            <ArticleSideBar
              article={article}
              steps={steps}
              isWixStudioUser={isWixStudioUser}
              relatedArticles={relatedArticles}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export const getServerSideProps = withAppServerProps(
  async ({ req, res, locale, query, isWixEmployee }) => {
    const logger = createLogger(LoggerNamespaces.Article);
    const { articleTitle, alternativeContent } = query;
    const withAlternativeContent = alternativeContent === 'true';
    let article: Pick<GetArticleResponse, 'article'>['article'];
    let relatedArticles: Pick<GetArticleResponse, 'relatedArticles'>['relatedArticles'];

    const shouldConductVersionB = withAlternativeContent && isWixEmployee;
    const { VERSION_A, VERSION_B } = ArticleAlternativeContentNumber;
    try {
      const data = await getArticle(
        req.aspects,
        articleTitle as string,
        locale,
        shouldConductVersionB ? VERSION_B : isWixEmployee ? VERSION_A : undefined
      );

      article = data.article;
      relatedArticles = data.relatedArticles;
    } catch (error: any) {
      if (error instanceof RedirectionError) {
        return {
          redirect: {
            statusCode: 301,
            destination: encodeURI(`/${locale}/article${error.location}`),
          },
        };
      }
      throw error;
    }

    const allLabels = isProduction()
      ? await getLabels(req.aspects, res.locals.redisClient)
      : MOCKED_ROADMAP_LABELS;
    const roadmapLabels = getRoadmapLabels(allLabels as Label[]);

    if (shouldRedirectArticle(article.id)) {
      const isEnglish = article.locale === LOCALES.EN;

      const redirectArticle = !isEnglish
        ? await getArticleById(req.aspects, article.id as string, LOCALES.EN)
        : article;

      const wixDocsUrl = await getWixDocsUrl(
        res.locals.appContext,
        redirectArticle.url as string
      );

      if (wixDocsUrl) {
        const userAgent = req.headers['user-agent'];
        if (isProduction() && (userAgent && !isSledUserAgent(userAgent))) {
          const logData = {
            from: redirectArticle.url,
            to: wixDocsUrl,
            referrer: req.headers.referer,
          };
          logger.info('Redirecting to Wix Docs', logData);
        }

        return {
          redirect: {
            statusCode: 301,
            destination: wixDocsUrl,
          },
        };
      }
    }

    const pageSeo = await getPageSeo(
      req.aspects,
      locale,
      PageType.Article,
      res.locals.redisClient
    );

    const getSteps = () => {
      if (!article.content) {
        return [];
      }
      const $ = cheerio.load(article.content);
      const newSteps = $('[level="2"],[level="3"],[level="4"]')
        .get()
        .filter((step) => step.attribs.id?.length > 0)
        .map((step) => ({
          id: step.attribs.id,
          text: $(step).text(),
          level: step.attribs.level,
        }));

      return newSteps.length > 1 ? newSteps : [];
    };

    return {
      props: {
        relatedArticles,
        article,
        pageData: {
          article: {
            ...article,
            withRelatedArticles:
              !!relatedArticles && relatedArticles.length,
          },
          roadmapData: { roadmapLabels },
        },
        pageType: PAGES.ARTICLE,
        itemId: article.id,
        itemType: mapArticleTypeToItemType(article?.type),
        pageSeo,
        roadmapLabels,
        steps: getSteps(),
      }
    };
  }
);

export default ArticlePage;
